import _ from "lodash"
import ep from "~/api/endpoint"
import query_params from "~/api/query-params"

const state = () => ({
  oho_socket: null,
  is_ws_disconnect: false,
  is_ws_error: false,
  is_show_reload_chat_list_btn: false,
  is_show_chat_list_loader: false,
  is_reload_chat_list_after_reconnect_ws: false,
  disconnect_timestamp: "",
})

const mutations = {
  setOhoSocket(state, socket) {
    state.oho_socket = socket
  },
  setIsWsDisconnect(state, data) {
    state.is_ws_disconnect = data
  },
  setIsWsError(state, data) {
    state.is_ws_error = data
  },
  setIsReloadChatListAfterReconnectWS(state, data) {
    state.is_reload_chat_list_after_reconnect_ws = data
  },
  setIsShowReloadChatListBtn(state, data) {
    state.is_show_reload_chat_list_btn = data
  },
  setIsShowChatListLoader(state, data) {
    state.is_show_chat_list_loader = data
  },
  setDisconnectTimestamp(state, data) {
    state.disconnect_timestamp = data
  },
}

const actions = {
  setupSocket({ dispatch, state }, [socket, getAuth]) {
    if (!socket) return
    // Start connection, authentication and reconnection mechanism
    dispatch("setupConnectionLifeCycle", [socket, getAuth])

    // Here, define application-level events
    dispatch("registerEvents", socket)
  },
  setupConnectionLifeCycle({ commit, state }, [socket, getAuth]) {
    // Basic socket.io reconnect mechanism
    // You don't need to change these in most cases
    socket.on("connecting", () => {
      if (!process.client) return

      this.$logger.info("Socket connecting")
    })

    socket.on("connect", async () => {
      if (!process.client) return

      // Enabled chat action
      if (state.is_ws_disconnect || !state.oho_socket.connected) {
        commit("setIsWsDisconnect", false)

        try {
          const res = await this.$ohoMemberApi.$get(ep.contact_chat_search, {
            params: {
              "updated_at[$gte]": state.disconnect_timestamp,
              "updated_at[$lte]": new Date().toISOString(),
              "$sort[last_active_at]": -1,
              $skip: 0,
              $limit: 20,
              ...query_params.contact_default,
            },
          })

          commit("setDisconnectTimestamp", null)

          if (res?.data?.length > 0) {
            const room_list_el = document.querySelector("#room_list")
            const room_list_loader_el =
              document.querySelector("#room_list_loader")
            const room_list_loader_height =
              room_list_loader_el?.clientHeight || 0

            if (room_list_el) {
              const is_room_list_scrollable =
                room_list_el.scrollHeight > room_list_el.clientHeight
              const room_list_scroll_top = room_list_el.scrollTop || 0

              if (is_room_list_scrollable) {
                if (
                  room_list_loader_height === 0 &&
                  room_list_scroll_top === 0
                ) {
                  room_list_el.scroll({ top: 2 })
                } else if (
                  room_list_loader_height > 0 &&
                  room_list_scroll_top > 0 &&
                  room_list_scroll_top <= 50
                ) {
                  room_list_el.scroll({ top: 50 })
                }

                commit("setIsShowReloadChatListBtn", true)
                commit("setIsShowChatListLoader", true)
              } else {
                commit("setIsShowReloadChatListBtn", true)
              }
            }
          }
        } catch (error) {
          this.$logger.error("Find missing chat room failed", error)
        }
      }

      this.$logger.info("Socket connected. Socket ID:", socket.id)
      // Authenticate using current member's JWT token
      const authInfo = getAuth()
      socket.emit("login/member", authInfo)
    })

    socket.on("disconnect", (reason) => {
      if (!process.client) return

      if (!state.disconnect_timestamp) {
        commit("setDisconnectTimestamp", new Date().toISOString())
      }

      commit("setIsWsDisconnect", true)
      commit("setIsShowReloadChatListBtn", false)

      setTimeout(() => {
        socket.connect()
      }, 1000)

      this.$logger.info(`Socket disconnected: ${reason}.`)
    })

    socket.on("connect_error", (error) => {
      if (!process.client) return

      this.$logger.error(
        `Socket connected error: ${error.message}. Check if API/Socket server is down.`
      )
    })

    // IO manager
    socket.io.on("reconnect_attempt", (attempt) => {
      if (!process.client) return

      this.$logger.info("Socket reconnection attempt: ", attempt)
    })
    socket.io.on("reconnect", (attempt) => {
      if (!process.client) return

      this.$logger.info(`Socket reconnected in ${attempt} attempts!`)
    })
    socket.io.on("reconnect_error", (error) => {
      if (!process.client) return

      this.$logger.error(`Socket reconnection error: ${error.message}`)
    })
    socket.io.on("reconnect_failed", () => {
      if (!process.client) return

      // Set websocket error upon reconnect failed
      commit("setIsWsError", true)

      this.$logger.warn(`Socket reconnection max attempt. Stop reconnection.`)
    })
    socket.io.on("error", (error) => {
      if (!process.client) return

      this.$logger.error(`Socket error occurred: ${error.message}`)
    })

    commit("setOhoSocket", socket)
  },
  registerEvents({ rootState, commit, dispatch }, socket) {
    const is_onboarding = $nuxt.$route.path.split("/")[3] === "onboarding"
    // Here, define application-level events

    // Authenticate member OK
    // @param {string} member.userId
    // @param {string} member.businessId
    // @param {string} member.memberId
    // @param {string} member.role
    socket.on("login/member authenticated", (member) => {
      if (!process.client) return

      this.$logger.info("[socket] Member authenticated <3", member)
    })

    // New message arrives
    socket.on("chat/message created", (message) => {
      if (!process.client) return

      if (is_onboarding) return
      // console.log("[socket] chat/message created:", message)
      dispatch("handleUpdateContact", { event_message: message })
    })

    // New message my chat
    socket.on("chat/me/message created", (message) => {
      if (!process.client) return

      if (is_onboarding) return
      // console.log("[socket] chat/me/message created:", message)
      dispatch("newCustomerMessage", message)
      if (!rootState.window_focused) dispatch("setFavicon", "/favicon-noti.png")
    })

    // New message from no assignee contacts
    socket.on("chat/bot/message created", (message) => {
      if (!process.client) return

      if (is_onboarding) return
      // console.log("[socket] chat/bot/message created:", message)
      dispatch("newCustomerMessage", message)
      // if (!rootState.window_focused) dispatch("setFavicon", "/favicon-noti.png")
    })

    // New message from request contacts
    socket.on("chat/request/message created", (message) => {
      if (!process.client) return

      if (is_onboarding) return
      // console.log("[socket] chat/request/message created:", message)
      dispatch("newCustomerMessage", message)
      // if (!rootState.window_focused) dispatch("setFavicon", "/favicon-noti.png")
    })

    // New request
    socket.on("chat/request created", (message) => {
      if (!process.client) return

      if (is_onboarding) return
      // console.log("[socket] chat/request ", message);
      dispatch("newCustomerRequest", message)
      if (!rootState.window_focused) dispatch("setFavicon", "/favicon-noti.png")
    })

    // You're assigned to chatroom by the other admin
    socket.on("chat/assign created", (message) => {
      if (!process.client) return

      if (is_onboarding) return
      // console.log("[socket] chat/assign ", message);
      dispatch("youAssignedToChatroom", message)
      // this.$soundNoti()
      // if (!rootState.window_focused) dispatch("setFavicon", "/favicon-noti.png")
    })

    socket.on("chat/assign accepted", (message) => {
      if (!process.client) return
      if (is_onboarding) return

      dispatch("acceptedAssign", message)
      if (!rootState.window_focused) dispatch("setFavicon", "/favicon-noti.png")
    })

    socket.on("chat/assign canceled", (message) => {
      if (!process.client) return
      if (is_onboarding) return

      dispatch("canceledAssign", message)
      if (!rootState.window_focused) dispatch("setFavicon", "/favicon-noti.png")
    })

    socket.on("chat/assign rejected", (message) => {
      if (!process.client) return
      if (is_onboarding) return

      dispatch("rejectedAssign", message)
      if (!rootState.window_focused) dispatch("setFavicon", "/favicon-noti.png")
    })

    // Other unassigns you
    socket.on("chat/assign deleted", (message) => {
      if (!process.client) return

      if (is_onboarding) return
      // console.log("[socket] chat/assign deleted", message);
      dispatch("otherUnassignYou", message)
    })

    // Closed chat by the other admin
    socket.on("chat/close created", (message) => {
      if (!process.client) return

      if (is_onboarding) return
      // console.log("[socket] chat/close ", message);
      dispatch("closedChatByOtherAdmin", message)
    })

    // Sending bulk message
    socket.on("chat/me/bulk-message updated", (message) => {
      if (!process.client) return

      if (is_onboarding) return
      // console.log("[socket] chat/close ", message);
      dispatch("sendingBulkMessage", message)
    })

    socket.on("chat/status updated", async (message) => {
      if (!process.client) return

      if (is_onboarding) return
      dispatch("setContactAggregate", message)
    })

    socket.on("team/member created", async (message) => {
      if (!process.client) return
      if (is_onboarding) return

      dispatch("teamAddMember", message)
    })

    socket.on("team/member deleted", async (message) => {
      if (!process.client) return
      if (is_onboarding) return

      dispatch("teamRemoveMember", message)
    })

    socket.on("member/online-status updated", async (message) => {
      if (!process.client) return
      if (is_onboarding) return

      dispatch("updateMemberOnlineStatus", message)
    })

    socket.on("online-status aggregate", async (message) => {
      if (!process.client) return
      if (is_onboarding) return

      dispatch("setOnlineStatusAggregate", message)
    })

    socket.on("contact/profile updated", async (message) => {
      if (!process.client) return
      if (is_onboarding) return

      dispatch("updateContactData", { contact: message })
    })

    socket.on("contact/chat-tag updated", async (message) => {
      if (!process.client) return
      if (is_onboarding) return

      dispatch("updateContactData", {
        contact: message,
        update_fields: ["labels"],
      })
    })

    socket.on("contact/tag updated", async (message) => {
      if (!process.client) return
      if (is_onboarding) return

      dispatch("updateContactData", {
        contact: message,
        update_fields: ["tags"],
      })
    })

    socket.on("contact/notes updated", async (message) => {
      if (!process.client) return
      if (is_onboarding) return

      dispatch("updateContactData", {
        contact: message,
        update_fields: ["notes"],
      })
    })

    socket.on("tag/contact_label updated", async (message) => {
      if (!process.client) return
      if (is_onboarding) return

      dispatch("updateTagChat", { tag_chat: message, event: "edit" })
    })

    socket.on("tag/contact_label deleted", async (message) => {
      if (!process.client) return
      if (is_onboarding) return

      dispatch("updateTagChat", { tag_chat: message, event: "delete" })
    })

    socket.on("tag/tag updated", async (message) => {
      if (!process.client) return
      if (is_onboarding) return

      dispatch("updateContactTag", { contact_tag: message, event: "edit" })
    })

    socket.on("tag/tag deleted", async (message) => {
      if (!process.client) return
      if (is_onboarding) return

      dispatch("updateContactTag", { contact_tag: message, event: "delete" })
    })

    socket.on("channel delete", async (message) => {
      if (!process.client) return
      if (is_onboarding) return

      if (_.get(message, "channel_id")) {
        dispatch("informDeleteChannel")
        dispatch("setOhoMemberPreferences", {
          ...state.oho_member_preferences,
          is_acked_channel_deletion: false,
        })
      }
    })

    socket.on("member/role updated", async (message) => {
      if (!process.client) return
      if (is_onboarding) return

      if (_.get(message, "acked_role_change.should_ack") === true) {
        commit("setAlertChangeRoleDialog", {
          role: _.get(message, "role"),
          changed_by: _.get(message, "acked_role_change.changed_by"),
          should_ack: true,
        })
      }
    })

    /*
    Event name should be in this form: "{{resource}} {{method}}""
    - resource = Similar to Feathers services. For sub services, use slashes.
    - method = REST methods e.g. created, deleted, updated
    */
  },
  setFavicon({}, favicon) {
    const link = document.querySelector("link[rel~='icon']")

    if (!link) {
      link = document.createElement("link")
      link.rel = "icon"
      document.getElementsByTagName("head")[0].appendChild(link)
    }

    link.href = favicon
  },
}

export default { state, mutations, actions }
