import _ from "lodash"
import moment from "moment"
import Vue from "vue"
import ep from "~/api/endpoint"
import query_params from "~/api/query-params"
import SystemFeedback from "~/system-feedback"
import { DEFAULT_UPDATE_FIELDS } from "../../constants/contact"

const state = () => ({
  stream_client: null,
  conversation_width: 0,
  current_contact: null,
  contact_list: {
    total: 0,
    limit: 20,
    skip: 0,
    data: [],
  },
  chat_noti: null,
  current_contact_loading: false,
  current_stream_channel_loading: false,
  input_search: "",
  chat_menu: {
    token: null,
    status: null,
    assignee: null,
    team: null,
    assign_type: null,
  },
  contact_list_state: [],
  limit_contact_list_size: 500,
  is_limit_contact_list: true,
})

const mutations = {
  setContactState(state, data) {
    state.contact_list_state.push(data)
  },
  updateContactState(state, { index, data }) {
    Vue.set(state.contact_list_state, index, data)
  },
  setStreamClient(state, client) {
    state.stream_client = client
  },
  setInputSearch(state, keyword) {
    state.input_search = keyword
  },
  setChatNoti(state, noti) {
    state.chat_noti = noti
  },
  setConversationWidth(state, width) {
    state.conversation_width = width
  },
  setCurrentContact(state, contact) {
    state.current_contact = null
    state.current_contact = contact
  },
  setContactList(state, contact) {
    state.contact_list = contact
  },
  updateContactListData(state, [index, data]) {
    Vue.set(state.contact_list.data, index, data)
  },
  setDefaultContactListSkip(state) {
    state.contact_list.skip = 0
  },
  setContactListSkip(state) {
    const contact_list_length = _.get(state, "contact_list.data.length", 0)
    state.contact_list.skip = contact_list_length
  },
  addContactListData(state, data) {
    state.contact_list.data.push(...data)
  },
  addContactListDataFromHead(state, data) {
    state.contact_list.data.unshift(...data)
  },
  removeContactListData(state, index) {
    state.contact_list.data.splice(index, 1)
    state.contact_list.total -= 1
  },
  setCurrentContactLoading(state, status) {
    state.current_contact_loading = status
  },
  setCurrentStreamChannelLoading(state, status) {
    state.current_stream_channel_loading = status
  },
  addInvolvedCaseToContactList(state, [index, data]) {
    state.contact_list.data[index].my_involve_case_ids.push(data)
  },
  setActiveChatMenu(state, { token, status, assignee, team, assign_type }) {
    state.chat_menu.token = token
    state.chat_menu.status = status
    state.chat_menu.assignee = assignee
    state.chat_menu.team = team
    state.chat_menu.assign_type = assign_type
  },
  resetContactList(state) {
    state.contact_list = {
      total: 0,
      limit: 20,
      skip: 0,
      data: [],
    }
  },
  removeLastContact(state) {
    state.contact_list.data.pop()
  },
  setIsLimitContactList(state, data) {
    state.is_limit_contact_list = data
  },
}

const getters = {
  getCurrentContactData: (state) => (contact_id) => {
    if (contact_id) {
      return state.contact_list.data.find((item) => item._id === contact_id)
    } else {
      return null
    }
  },
  current_contact_state: (state) => {
    let current_contact_id = _.get(state, "current_contact._id")
    const contact_list_state = _.get(state, "contact_list_state", [])
    return contact_list_state.find((item) => item._id === current_contact_id)
  },
}

const actions = {
  handleSetContactState({ state, commit }, contact_id) {
    const contact_list_state = _.get(state, "contact_list_state", [])
    const find_state_index = contact_list_state.findIndex(
      (item) => item._id === contact_id
    )

    if (find_state_index > -1) return

    const initial_state = {
      _id: contact_id,
      is_disabled_chat_action_btn: false,
      is_disabled_end_chat_btn: false,
      disabled_end_chat_reason: "",
    }
    commit("setContactState", initial_state)
  },
  handleUpdateContactState({ state, commit }, { contact_id, data }) {
    const contact_list_state = _.get(state, "contact_list_state", [])
    const find_state_index = contact_list_state.findIndex(
      (item) => item._id === contact_id
    )
    const state_data = contact_list_state[find_state_index]

    if (find_state_index === -1) return

    commit("updateContactState", {
      index: find_state_index,
      data: { ...state_data, ...data },
    })
  },
  getRoomStatusQuery({ rootState }, query) {
    let params = {}
    const status = _.get(query, "status")
    const my_id = _.get(rootState.oho_member, "member._id")

    switch (status) {
      case "assign-me":
        params["assign_to.member_id"] = my_id
        break

      case "assign-my-team":
        params["assign_to.team_id"] = _.get(query, "team")
        params["assign_to.member_id"] = "null"
        break

      case "team":
        params["$or[0][assignee.team_id]"] = _.get(query, "team")
        params["$or[0][assign_to.team_id]"] = "null"
        params["$or[1][assign_to.team_id]"] = _.get(query, "team")
        break

      case "pinned":
        params["starred_by_members"] = my_id
        break

      case "me":
      case "assignee":
        const assignee = status === "me" ? my_id : _.get(query, "assignee")
        const team = _.get(query, "team")

        params["$or[0][assignee.member_id]"] = assignee
        params["$or[0][assignee.team_id]"] = team
        params["$or[0][assign_to.team_id]"] = "null"
        params["$or[1][assign_to.member_id]"] = assignee
        params["$or[1][assign_to.team_id]"] = team
        break

      case "request":
        params["status"] = "request"
        params["assign_to"] = "null"
        break

      case "bot":
        params["status"] = "bot"
        params["assign_to"] = "null"
        params["chat_status"] = "replied"
        break

      case "no-bot":
        params["status"] = "bot"
        params["assign_to"] = "null"
        params["chat_status"] = "fallback"
        break

      case "agent":
        params["$or[0][status]"] = "agent"
        params["$or[0][assign_to]"] = "null"
        params["$or[0][assignee.member_id][$ne]"] = my_id
        params["$or[1][assign_to.member_id][$nin]"] = ["null", my_id]
        break

      case "end-case":
        params["status"] = "bot"
        params["assign_to"] = "null"
        params["chat_status"] = "null"
        break

      default:
        break
    }

    return params
  },
  getRoomStatus({ rootState }, room) {
    let status = ""
    const room_status = _.get(room, "status")
    const chat_status = _.get(room, "chat_status")
    const my_id = _.get(rootState, "oho_member.member._id")
    const my_teams = _.get(rootState, "oho_member.member.teams")
    const assignee_id = _.get(room, "assignee.member_id._id")
    const assign_to_id = _.get(room, "assign_to.member_id._id")
    const assign_to_team_id = _.get(room, "assign_to.team_id._id")
    const route_status = _.get($nuxt, "$route.query.status")
    const route_assignee = _.get($nuxt, "$route.query.assignee")

    const is_bot_status = room_status === "bot"
    const is_agent_status = room_status === "agent"
    const is_request_status = room_status === "request"

    const is_assigning_to_my_team =
      assign_to_team_id && my_teams.includes(assign_to_team_id) && !assign_to_id
    const is_assigning_to_me = assign_to_id === my_id
    const is_my_assigned_room = assignee_id === my_id && !assign_to_id

    switch (true) {
      // แชทของคุณ
      case (is_agent_status && is_my_assigned_room) || is_assigning_to_me:
        status = "me"
        break

      // แชทที่ส่งให้คุณ
      case is_assigning_to_me:
        status = "assign-me"
        break

      // แชทที่ส่งให้ทีม
      case is_assigning_to_my_team:
        status = "assign-my-team"
        break

      // เรียกแอดมิน
      case is_request_status && !assign_to_id:
        status = "request"
        break

      // แชทบอทตอบแล้ว
      case is_bot_status && chat_status === "replied" && !assign_to_id:
        status = "bot"
        break

      // แชทบอทตอบไม่ได้
      case is_bot_status && chat_status === "fallback" && !assign_to_id:
        status = "no-bot"
        break

      // แชทของสมาชิก
      case is_agent_status &&
        (route_assignee === assign_to_id || route_assignee === assignee_id):
        status = "assignee"
        break

      // แชทของทีม
      case !!assign_to_id || is_agent_status:
        status = "team"
        break

      //จบแชทแล้ว
      default:
        status = "end-case"
        break
    }

    return status
  },
  getRoomLabel({ rootState }, room) {
    let status = ""
    const room_status = _.get(room, "status")
    const chat_status = _.get(room, "chat_status")
    const assignee_id = _.get(room, "assignee.member_id._id")
    const assign_to_id = _.get(room, "assign_to.member_id._id")
    const my_id = _.get(rootState.oho_member, "member._id")

    const is_bot_status = room_status === "bot"
    const is_agent_status = room_status === "agent"
    const is_request_status = room_status === "request"

    const is_my_assigned_room = assignee_id === my_id && !assign_to_id

    switch (true) {
      // แชทของคุณ
      case is_agent_status && is_my_assigned_room:
        status = "me"
        break

      // เรียกแอดมิน
      case is_request_status:
        status = "request"
        break

      // แชทบอทตอบแล้ว
      case is_bot_status && chat_status === "replied":
        status = "bot"
        break

      // แชทบอทตอบไม่ได้
      case is_bot_status && chat_status === "fallback":
        status = "no-bot"
        break

      // ยังไม่ทักแชท
      case is_bot_status && chat_status === "greeting_no_chat":
        status = "greeting-no-chat"
        break

      // แชทของสมาชิก
      case is_agent_status && assignee_id:
        status = "assignee"
        break

      // แชทของทีม
      case is_agent_status:
        status = "agent"
        break

      //จบแชทแล้ว
      default:
        status = "end-case"
        break
    }
    return status
  },
  getNotificationAction({ state }, router) {
    const on_click = () => {
      state.chat_noti.close()
      $nuxt.$router.push(router)
    }
    return on_click
  },
  async newCustomerRequest({ commit, dispatch, rootState }, event_message) {
    const contact_id = _.get(event_message, "contact_id")
    const on_click = await dispatch("getNotificationAction", {
      path: `/business/${_.get(rootState, "business._id")}/smartchat`,
      query: { room: contact_id },
    })

    const is_enabled_notification = _.get(
      rootState,
      "oho_member.member.is_enabled_notification.web_app"
    )

    if (is_enabled_notification) {
      const noti = SystemFeedback("0001", event_message, on_click)
      commit("setChatNoti", noti)
      this.$soundNoti()
    }
  },
  handleSetUpdateContactList({ state, commit }, { fnUpdate, contact_list }) {
    if (_.isEmpty(contact_list)) return

    const input_search = state.input_search.trim()

    let res = contact_list
    if (input_search) {
      let raw_data = contact_list
      if (typeof contact_list == "object") {
        raw_data = _.get(contact_list, "data", [])
      }

      let data_set = raw_data
      function escapeRegExp(string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&") // $& means the whole matched string
      }
      try {
        data_set = raw_data.map((d) => {
          const regex = new RegExp(escapeRegExp(input_search), "i")
          const room_display_name = this.$smartChatHelper.highlightSearchMatch(
            input_search,
            d.display_name,
            true,
            regex
          )

          return room_display_name
            ? {
                ...d,
                room_display_name,
              }
            : d
        })
      } catch (error) {}

      if (typeof contact_list == "object") {
        res.data = data_set
      } else {
        res = data_set
      }
    }

    // Set Data
    if (fnUpdate == "updateContactListData") {
      const index = _.get(contact_list, "index")
      res = _.get(res, "data", {})

      commit(fnUpdate, [index, res])
    } else {
      commit(fnUpdate, res)
    }
  },
  async handleUpdateContact(
    { rootState, state, commit, dispatch },
    { event_message, update_fields = DEFAULT_UPDATE_FIELDS }
  ) {
    const my_id = _.get(rootState.oho_member, "member._id")
    const current_contact_id = _.get(state.current_contact, "_id")
    const event_message_id = _.get(event_message, "_id")
    const contacts = _.get(state, "contact_list.data", [])
    const contact_index = _.findIndex(contacts, { _id: event_message_id })
    const contact = _.get(contacts, `[${contact_index}]`)
    const current_contact = _.get(state, "current_contact")

    // If contact found, pick only updated fields from socket event message
    const update_event_message = _.pick(event_message, update_fields)

    let contact_data = null
    if (contact) {
      contact_data = {
        ...contact,
        ...update_event_message,
      }
    } else if (event_message_id === current_contact_id) {
      contact_data = {
        ...current_contact,
        ...update_event_message,
      }
    } else {
      contact_data = event_message
    }

    const input_search = _.get(state, "input_search")
    const prev_updated_at = _.get(contact, "updated_at") || 0
    const next_updated_at = _.get(event_message, "updated_at") || 0
    const prev_updated_at_date = moment(prev_updated_at)
    const next_updated_at_date = moment(next_updated_at)

    // Check updated_at outdated yet?
    if (!(prev_updated_at_date <= next_updated_at_date)) return

    if (current_contact_id === event_message_id) {
      commit("setCurrentContact", contact_data)
      if (contact_index > -1) {
        commit("updateContactListData", [contact_index, contact_data])
      }
    }

    const is_not_match_filter = this.$filterHelper.isNotMatchChatListFilter(
      contact_data,
      input_search,
      rootState
    )
    if (is_not_match_filter) return

    highlightDisplayName(input_search, contact_data)

    // Special cases that are not updated chat room list
    if (this.$contact.isStarred(event_message, my_id) && contact) {
      commit("updateContactListData", [contact_index, contact_data])
      return
    }

    const contact_list_length = _.get(contacts, "length", 0)
    const total_contact = _.get(state, "contact_list.total", 0)
    const sort_chat_list = _.get(rootState, "filter.sort_chat_list", -1)
    const should_block_new_chat =
      (sort_chat_list === 1 && contact_list_length < total_contact) ||
      rootState.websocket.is_show_reload_chat_list_btn

    const active_chat_menu_token = _.get(state, "chat_menu.token")
    const next_contact_status_token = await dispatch(
      "generateNextContactToken",
      event_message
    )

    const should_update_contact_list =
      active_chat_menu_token === next_contact_status_token
    const contact_visibility = this.$contact.visibility(contact_data)

    // Contact found
    if (contact_index > -1) {
      if (should_update_contact_list && contact_visibility) {
        commit("updateContactListData", [contact_index, contact_data])
      } else {
        commit("removeContactListData", contact_index)
      }
    } else {
      if (
        !should_block_new_chat &&
        should_update_contact_list &&
        contact_visibility
      ) {
        dispatch("handleLimitContactList")
        dispatch("handleSetUpdateContactList", {
          fnUpdate: "addContactListDataFromHead",
          contact_list: [contact_data],
        })
      }
    }
  },
  async newCustomerMessage(
    { state, commit, dispatch, rootState },
    event_message
  ) {
    const contact_id = _.get(event_message, "contact_id")
    if (_.get(state.current_contact, "_id") == contact_id) return
    const on_click = await dispatch("getNotificationAction", {
      path: `/business/${_.get(rootState, "business._id")}/smartchat`,
      query: { room: contact_id },
    })

    const is_enabled_notification = _.get(
      rootState,
      "oho_member.member.is_enabled_notification.web_app"
    )
    if (is_enabled_notification) {
      const noti = SystemFeedback("0010", event_message, on_click)
      commit("setChatNoti", noti)
      this.$soundNoti()
    }
  },
  async youAssignedToChatroom({ commit, dispatch, rootState }, event_message) {
    let system_feedback_id = ""
    const contact_id = _.get(event_message, "contact_id")
    const is_assign_by_bot = _.get(event_message, "is_assign_by_bot")
    const auto_assign_action_type = _.get(
      event_message,
      "auto_assign_action_type"
    )
    const is_assign_to_team = _.get(event_message, "is_assign_to_team")
    const is_auto_assign = _.get(event_message, "is_auto_assign")
    const oho_member = _.get(rootState, "oho_member.member")

    if (is_assign_by_bot && is_assign_to_team) {
      system_feedback_id = "0051"
    } else if (is_auto_assign) {
      const auto_assign_info = this.$autoAssignHelper.eventStatus(
        auto_assign_action_type
      )
      const auto_assign_system_feedback_id = _.get(
        auto_assign_info,
        "system_feedback_id"
      )

      if (auto_assign_system_feedback_id) {
        system_feedback_id = auto_assign_system_feedback_id
      }
    } else if (is_assign_by_bot) {
      system_feedback_id = "0005"
    } else if (is_assign_to_team) {
      system_feedback_id = "0050"
    } else {
      system_feedback_id = "0004"
    }

    const on_click = await dispatch("getNotificationAction", {
      path: `/business/${_.get(rootState, "business._id")}/smartchat`,
      query: {
        status: oho_member.role === "agent" ? "me" : undefined,
        room: contact_id,
      },
    })
    // const noti = SystemFeedback(system_feedback_id, event_message, on_click)
    // commit("setChatNoti", noti)

    const my_name = _.get(rootState, "oho_member.member.display_name")
    const is_enabled_notification = _.get(
      rootState,
      "oho_member.member.is_enabled_notification.web_app"
    )

    if (my_name !== event_message.assigner_name && is_enabled_notification) {
      const noti = SystemFeedback(system_feedback_id, event_message, on_click)
      commit("setChatNoti", noti)

      this.$soundNoti()
    } else if (
      my_name !== event_message.assigner_name &&
      !rootState.window_focused
    ) {
      dispatch("setFavicon", "/favicon-noti.png")
    }
  },
  async otherUnassignYou({ commit, dispatch, rootState }, event_message) {
    const contact_id = _.get(event_message, "contact_id")
    const on_click = await dispatch("getNotificationAction", {
      path: `/business/${_.get(rootState, "business._id")}/smartchat`,
      query: { room: contact_id },
    })
    // const noti = SystemFeedback("0006", event_message, on_click)
    // commit("setChatNoti", noti)

    const my_name = _.get(rootState, "oho_member.member.display_name")
    const is_enabled_notification = _.get(
      rootState,
      "oho_member.member.is_enabled_notification.web_app"
    )

    if (my_name !== event_message.assigner_name && is_enabled_notification) {
      const noti = SystemFeedback("0006", event_message, on_click)
      commit("setChatNoti", noti)

      this.$soundNoti()
    } else if (
      my_name !== event_message.assigner_name &&
      !rootState.window_focused
    ) {
      dispatch("setFavicon", "/favicon-noti.png")
    }
  },
  async closedChatByOtherAdmin({ commit, dispatch, rootState }, event_message) {
    const contact_id = _.get(event_message, "contact_id")
    const on_click = await dispatch("getNotificationAction", {
      path: `/business/${_.get(rootState, "business._id")}/smartchat`,
      query: { room: contact_id },
    })

    const is_enabled_notification = _.get(
      rootState,
      "oho_member.member.is_enabled_notification.web_app"
    )
    if (is_enabled_notification) {
      const noti = SystemFeedback("0009", event_message, on_click)
      commit("setChatNoti", noti)
    }
  },
  async acceptedAssign({ rootState, commit, dispatch }, event_message) {
    const contact_id = _.get(event_message, "contact_id")
    const on_click = await dispatch("getNotificationAction", {
      path: `/business/${_.get(rootState, "business._id")}/smartchat`,
      query: { room: contact_id },
    })
    const is_enabled_notification = _.get(
      rootState,
      "oho_member.member.is_enabled_notification.web_app"
    )
    if (is_enabled_notification) {
      const noti = SystemFeedback("0047", event_message, on_click)
      commit("setChatNoti", noti)
      this.$soundNoti()
    }
    if (!rootState.window_focused) dispatch("setFavicon", "/favicon-noti.png")
  },
  async rejectedAssign({ rootState, commit, dispatch }, event_message) {
    const contact_id = _.get(event_message, "contact_id")
    const on_click = await dispatch("getNotificationAction", {
      path: `/business/${_.get(rootState, "business._id")}/smartchat`,
      query: { room: contact_id },
    })
    const is_enabled_notification = _.get(
      rootState,
      "oho_member.member.is_enabled_notification.web_app"
    )
    if (is_enabled_notification) {
      const noti = SystemFeedback("0048", event_message, on_click)
      commit("setChatNoti", noti)
      this.$soundNoti()
    }
    if (!rootState.window_focused) dispatch("setFavicon", "/favicon-noti.png")
  },
  async canceledAssign({ rootState, commit, dispatch }, event_message) {
    const oho_member = _.get(rootState, "oho_member.member")

    // ถ้าเป็น agent เมื่อโดนยกเลิกส่งแชท reset หน้า smartchat เป็น state ที่ยังไม่ได้เลือกห้องแชท
    if (oho_member.role === "agent") {
      commit("setCurrentContact", null)
      const new_query = _.omit($nuxt.$route.query, ["room"])
      $nuxt.$router.replace({ query: { ...new_query } })
    }

    const contact_id = _.get(event_message, "contact_id")
    const on_click = await dispatch("getNotificationAction", {
      path: `/business/${_.get(rootState, "business._id")}/smartchat`,
      query: { room: contact_id },
    })
    const is_enabled_notification = _.get(
      rootState,
      "oho_member.member.is_enabled_notification.web_app"
    )
    if (is_enabled_notification) {
      const noti = SystemFeedback("0049", event_message, on_click)
      commit("setChatNoti", noti)
      this.$soundNoti()
    }
    if (!rootState.window_focused) dispatch("setFavicon", "/favicon-noti.png")
  },
  async teamAddMember({ rootState, commit, dispatch }, event_message) {
    const team_id = _.get(event_message, "team_id")

    if (team_id) {
      commit("addTeamToMember", team_id)
    }

    const is_enabled_notification = _.get(
      rootState,
      "oho_member.member.is_enabled_notification.web_app"
    )
    if (is_enabled_notification) {
      const noti = SystemFeedback("0052", event_message)
      commit("setChatNoti", noti)
      this.$soundNoti()
    }
    if (!rootState.window_focused) dispatch("setFavicon", "/favicon-noti.png")
  },
  async teamRemoveMember({ commit }, event_message) {
    const team_id = _.get(event_message, "team_id")

    if (team_id) {
      commit("removeTeamFromMember", team_id)
    }
  },
  sendingBulkMessage({ commit, dispatch, rootState }, event) {
    $nuxt.$bulkMessageStatus(event.current_count, event.all_count)
    if (event.current_count >= event.all_count) {
      setTimeout(() => {
        $nuxt.$closeBulkMessageStatus()
      }, 2000)
    }
  },
  generateChatMenuToken({ commit }) {
    const route_status = _.get($nuxt, "$route.query.status")
    const route_assignee = _.get($nuxt, "$route.query.assignee")
    const route_team = _.get($nuxt, "$route.query.team")
    const route_assign_type = _.get($nuxt, "$route.query.assign-type")
    let active_status = null
    let active_assignee = null
    let active_team = null
    let active_assign_type = null

    switch (true) {
      // แชททั้งหมด
      case !route_status && !route_assignee && !route_team:
        active_status = "all"
        break

      // แชทที่ส่งให้คุณ
      case route_status === "assign-me":
        active_status = "assign-me"
        break

      // แชทที่ส่งให้ทีม แยกตามทีม
      case route_status === "assign-my-team":
        active_status = "assign-my-team"
        active_team = route_team
        break

      // แชทของคุณ
      case route_status === "me":
        active_status = "me"
        break

      // แชทของคุณแยกตามทีม
      case route_status === "assignee" && route_assign_type === "me":
        active_status = "me"
        active_team = route_team
        active_assign_type = "me"
        break

      // แชทปักหมุด
      case route_status === "pinned":
        active_status = "pinned"
        break

      // เรียกสมาชิก
      case route_status === "request":
        active_status = "request"
        break

      // แชทบอทตอบแล้ว
      case route_status === "bot":
        active_status = "bot"
        break

      // แชทบอทตอบไม่ได้
      case route_status === "no-bot":
        active_status = "no-bot"
        break

      // แชทของทีม
      case route_status === "team":
        active_status = "team"
        active_team = route_team
        break

      // แชทของทีมแยกตามสมาชิก
      case route_team && route_status === "assignee":
        active_status = "team"
        active_team = route_team
        active_assignee = route_assignee
        break

      // จบแชทแล้ว
      case route_status === "end-case":
        active_status = "end-case"
        break

      default:
        active_status = "all"
        break
    }

    const token = generateChatListToken({
      status: active_status,
      assignee: active_assignee,
      team: active_team,
      assign_type: active_assign_type,
    })

    const active_chat_menu_data = {
      token: token,
      status: active_status,
      assignee: active_assignee,
      team: active_team,
      assign_type: active_assign_type,
    }

    commit("setActiveChatMenu", active_chat_menu_data)
  },
  generateNextContactToken({ rootState, state }, event_message) {
    let next_status = null
    let next_assignee = null
    let next_team = null
    let next_assign_type = null

    const my_id = _.get(rootState, "oho_member.member._id")
    const my_teams = _.get(rootState, "oho_member.member.teams")
    const chat_menu_status = _.get(state, "chat_menu.status")
    const chat_menu_assignee = _.get(state, "chat_menu.assignee")
    const chat_menu_team = _.get(state, "chat_menu.team")
    const chat_menu_assign_type = _.get(state, "chat_menu.assign_type")
    const room_status = _.get(event_message, "status")
    const room_chat_status = _.get(event_message, "chat_status")
    const room_assignee_id = _.get(event_message, "assignee.member_id._id")
    const room_assignee_team_id = _.get(event_message, "assignee.team_id._id")
    const room_assign_to_id = _.get(event_message, "assign_to.member_id._id")
    const room_assign_to_team_id = _.get(event_message, "assign_to.team_id._id")

    const is_assign_me_logic =
      chat_menu_status === "assign-me" && room_assign_to_id === my_id

    const is_assign_my_team_logic =
      chat_menu_status === "assign-my-team" &&
      room_assign_to_team_id &&
      my_teams.includes(room_assign_to_team_id) &&
      !room_assign_to_id

    const is_me_logic =
      chat_menu_status === "me" &&
      ((room_status === "agent" &&
        room_assignee_id === my_id &&
        !room_assign_to_id) ||
        room_assign_to_id === my_id)

    const is_request_logic =
      chat_menu_status === "request" &&
      room_status === "request" &&
      !room_assign_to_id &&
      !room_assign_to_team_id

    const is_bot_logic =
      chat_menu_status === "bot" &&
      room_status === "bot" &&
      room_chat_status === "replied" &&
      !room_assign_to_id

    const is_no_bot_logic =
      chat_menu_status === "no-bot" &&
      room_status === "bot" &&
      room_chat_status === "fallback" &&
      !room_assign_to_id

    const is_team_logic =
      chat_menu_status === "team" &&
      (!!(
        (room_assignee_team_id && !room_assign_to_team_id) ||
        room_assign_to_team_id
      ) ||
        room_status === "agent")

    const is_end_case_logic =
      chat_menu_status === "end-case" &&
      room_status === "bot" &&
      !room_chat_status &&
      !room_assign_to_id &&
      !room_assign_to_team_id

    const is_pinned_logic = this.$contact.isStarred(event_message, my_id)

    switch (true) {
      case is_assign_me_logic:
        next_status = "assign-me"
        break

      case is_assign_my_team_logic:
        next_status = "assign-my-team"
        next_team = room_assign_to_team_id
        break

      case is_me_logic:
        if (chat_menu_team) {
          next_status = "me"
          next_team = room_assign_to_team_id || room_assignee_team_id
          next_assign_type = chat_menu_assign_type
        } else if (!chat_menu_team) {
          next_status = "me"
        }
        break

      case is_request_logic:
        next_status = "request"
        break

      case is_bot_logic:
        next_status = "bot"
        break

      case is_no_bot_logic:
        next_status = "no-bot"
        break

      case is_team_logic:
        if (chat_menu_team && !chat_menu_assignee) {
          next_status = "team"
          next_team = room_assign_to_team_id || room_assignee_team_id
        } else if (chat_menu_team && chat_menu_assignee) {
          next_status = "team"
          next_team = room_assign_to_team_id || room_assignee_team_id

          if (room_assign_to_id) {
            next_assignee = room_assign_to_id
          } else if (
            !room_assign_to_id &&
            !room_assign_to_team_id &&
            room_assignee_id
          ) {
            next_assignee = room_assignee_id
          } else {
            next_assignee = null
          }
        }
        break

      case is_end_case_logic:
        next_status = "end-case"
        break

      case is_pinned_logic:
        next_status = "pinned"
        break

      default:
        next_status = "all"
        break
    }

    const token = generateChatListToken({
      status: next_status,
      assignee: next_assignee,
      team: next_team,
      assign_type: next_assign_type,
    })

    return token
  },
  async updateContactData(
    { state, rootState, commit, dispatch },
    { contact, update_fields = [] }
  ) {
    const input_search = _.get(state, "input_search")
    const contact_list = _.get(state, "contact_list.data", [])
    const contact_id = _.get(contact, "_id")
    const contact_index = _.findIndex(contact_list, {
      _id: contact_id,
    })

    const current_contact = _.get(state, "current_contact", {})
    const current_contact_id = _.get(current_contact, "_id")
    const contact_tags = _.get(contact, "tags", [])
    const chat_tags = _.get(contact, "labels", [])
    const referral_tags = _.get(contact, "referrals", [])
    const contact_tags_id = contact_tags.map((item) => item._id)
    const chat_tags_id = chat_tags.map((item) => item._id)
    const referral_tags_id = referral_tags.map((item) => item._id)

    let pick_update_data = {}

    if (update_fields.length > 0) {
      pick_update_data = _.pick(contact, update_fields)
    } else {
      pick_update_data = contact
    }

    const filtered_contact_tag = this.$filterHelper.getFilterValue(
      rootState.filter.filter_chat_list,
      "contact_tag"
    )
    const is_has_contact_tag = _.has(contact, "tags")
    const is_filtered_contact_tag = (filtered_contact_tag || []).length > 0
    const is_contact_tag_in_tags_filter = _.chain(filtered_contact_tag)
      .difference(contact_tags_id)
      .size()
      .eq(0)
      .value()

    const filtered_chat_tag = this.$filterHelper.getFilterValue(
      rootState.filter.filter_chat_list,
      "chat_tag"
    )
    const is_has_chat_tag = _.has(contact, "labels")
    const is_filtered_chat_tag = (filtered_chat_tag || []).length > 0
    const is_chat_tag_in_tags_filter = _.chain(filtered_chat_tag)
      .difference(chat_tags_id)
      .size()
      .eq(0)
      .value()

    const filtered_referral_tag = this.$filterHelper.getFilterValue(
      rootState.filter.filter_chat_list,
      "referral_tag"
    )
    const is_has_referral_tag = _.has(contact, "referrals")
    const is_filtered_referral_tag = (filtered_referral_tag || []).length > 0
    const is_referral_tag_in_tags_filter = _.chain(filtered_referral_tag)
      .difference(referral_tags_id)
      .size()
      .eq(0)
      .value()

    if (current_contact_id === contact_id) {
      commit("setCurrentContact", { ...current_contact, ...pick_update_data })
    }

    const contact_list_length = _.get(state, "contact_list.data.length", 0)
    const total_contact = _.get(state, "contact_list.total", 0)
    const sort_chat_list = _.get(rootState, "filter.sort_chat_list", -1)

    const should_block_new_chat =
      sort_chat_list === 1 && contact_list_length < total_contact

    const contact_tag_filter_out_logic =
      is_filtered_contact_tag &&
      is_has_contact_tag &&
      !is_contact_tag_in_tags_filter
    const chat_tag_filter_out_logic =
      is_filtered_chat_tag && is_has_chat_tag && !is_chat_tag_in_tags_filter
    const referral_tag_filter_out_logic =
      is_filtered_referral_tag &&
      is_has_referral_tag &&
      !is_referral_tag_in_tags_filter

    if (contact_index > -1) {
      if (
        contact_tag_filter_out_logic ||
        chat_tag_filter_out_logic ||
        referral_tag_filter_out_logic
      ) {
        // Remove contact that are untagged from the chat list
        commit("removeContactListData", contact_index)
      } else {
        const contact_data_from_list = contact_list[contact_index]

        commit("updateContactListData", [
          contact_index,
          { ...contact_data_from_list, ...pick_update_data },
        ])
      }
    } else {
      // Add new contact to the chat list and fetch contact data from API

      if (
        (is_filtered_contact_tag && is_contact_tag_in_tags_filter) ||
        (is_filtered_chat_tag &&
          is_chat_tag_in_tags_filter &&
          !should_block_new_chat)
      ) {
        try {
          const res = await this.$ohoMemberApi.$get(ep.contact_chat_search, {
            params: {
              _id: contact_id,
              "$sort[last_active_at]": -1,
              ...query_params.contact_default,
            },
          })

          const contact_data = _.get(res, "data[0]")
          const active_chat_menu_token = _.get(state, "chat_menu.token")
          const next_contact_status_token = await dispatch(
            "generateNextContactToken",
            contact_data
          )
          const should_update_contact_list =
            active_chat_menu_token === next_contact_status_token
          const contact_visibility = this.$contact.visibility(contact_data)

          const is_not_match_filter =
            this.$filterHelper.isNotMatchChatListFilter(
              contact_data,
              input_search,
              rootState
            )

          if (
            is_not_match_filter ||
            !should_update_contact_list ||
            !contact_visibility
          ) {
            return
          }

          highlightDisplayName(input_search, contact_data)

          dispatch("handleSetUpdateContactList", {
            fnUpdate: "addContactListData",
            contact_list: [contact_data],
          })
        } catch (error) {
          this.$logger.error(_.get(error, "response.data"))
        }
      }
    }
  },
  async updateTagChat({ rootState, state, commit }, { tag_chat, event }) {
    const tag_chat_id = _.get(tag_chat, "_id")
    const contact_list = _.get(state, "contact_list.data") || []
    const current_contact = _.get(state, "current_contact") || {}
    const current_contact_id = _.get(current_contact, "_id")
    const filtered_chat_tag = this.$filterHelper.getFilterValue(
      rootState.filter.filter_chat_list,
      "chat_tag"
    )
    const filtered_tag_index = _.findIndex(
      filtered_chat_tag,
      (item) => item === tag_chat_id
    )

    if (current_contact_id) {
      const current_contact_labels = _.get(current_contact, "labels") || []
      const tag_index = _.findIndex(current_contact_labels, {
        _id: tag_chat_id,
      })

      if (tag_index > -1) {
        fnUpdate(event, current_contact_labels, tag_index, tag_chat)
      }
    }

    contact_list.forEach((d) => {
      const find_label_index = _.get(d, "labels", []).findIndex(
        (t) => t._id === tag_chat_id
      )

      if (find_label_index > -1) {
        fnUpdate(event, d.labels, find_label_index, tag_chat)
      }
    })

    // Clear the chat list upon removing tag that is being filtered
    if (event === "delete" && filtered_tag_index > -1) {
      commit("resetContactList")
    }
  },
  async updateContactTag({ rootState, state, commit }, { contact_tag, event }) {
    const contact_tag_id = _.get(contact_tag, "_id")
    const current_contact = _.get(state, "current_contact") || {}
    const current_contact_id = _.get(current_contact, "_id")
    const filtered_contact_tag = this.$filterHelper.getFilterValue(
      rootState.filter.filter_chat_list,
      "contact_tag"
    )
    const filtered_tag_index = _.findIndex(
      filtered_contact_tag,
      (item) => item === contact_tag_id
    )

    if (current_contact_id) {
      const current_contact_tags = _.get(current_contact, "tags") || []
      const tag_index = _.findIndex(current_contact_tags, {
        _id: contact_tag_id,
      })

      if (tag_index > -1) {
        fnUpdate(event, current_contact_tags, tag_index, contact_tag)
      }
    }

    // Clear the chat list upon removing tag that is being filtered
    if (event === "delete" && filtered_tag_index > -1) {
      commit("resetContactList")
    }
  },
  handleLimitContactList({ state, commit }) {
    if (
      state.contact_list.data.length >= state.limit_contact_list_size &&
      state.is_limit_contact_list
    ) {
      commit("removeLastContact")
    } else {
      return
    }
  },
}

const generateChatListToken = ({ status, assignee, team, assign_type }) => {
  let token = ""

  if (status) token = status
  if (assignee) token = token + "." + assignee
  if (team) token = token + "." + team
  if (assign_type) token = token + "." + assign_type

  return token
}

const fnUpdate = (event, data, index, label) => {
  switch (event) {
    case "edit":
      data.splice(index, 1, label)
      break
    case "delete":
      data.splice(index, 1)
      break
  }
}

const highlightDisplayName = (input_search, contact_data) => {
  const regex = new RegExp(input_search, "i")
  const contact_display_name = _.get(contact_data, "display_name") || ""
  const is_display_name_matches = contact_display_name.match(regex)

  if (input_search && is_display_name_matches) {
    contact_data["room_display_name"] = contact_data["display_name"].replaceAll(
      is_display_name_matches[0],
      `<b style="color: #E64949">${is_display_name_matches[0]}</b>`
    )
  }
}

export default { state, mutations, actions, getters }
