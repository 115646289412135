import { render, staticRenderFns } from "./default.vue?vue&type=template&id=54f7512c&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"
import style0 from "./default.vue?vue&type=style&index=0&id=54f7512c&prod&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FeedbackSurvey: require('/builds/oho/oho-web-app/components/FeedbackSurvey.vue').default,ChangeRoleAlert: require('/builds/oho/oho-web-app/components/ChangeRoleAlert.vue').default,Announcement: require('/builds/oho/oho-web-app/components/Announcement.vue').default,AlertBar: require('/builds/oho/oho-web-app/components/AlertBar.vue').default,Sidenav: require('/builds/oho/oho-web-app/components/Sidenav.vue').default,GlobalHeader: require('/builds/oho/oho-web-app/components/GlobalHeader.vue').default,SubMenuSide: require('/builds/oho/oho-web-app/components/SubMenuSide.vue').default,MobileNav: require('/builds/oho/oho-web-app/components/MobileNav.vue').default,CaseDialog: require('/builds/oho/oho-web-app/components/Case/Dialog.vue').default,CaseEndDialog: require('/builds/oho/oho-web-app/components/Case/EndDialog.vue').default})
