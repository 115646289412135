
import { mapState, mapGetters } from "vuex"
import _ from "lodash"
import Vue from "vue"
import Clipboard from "v-clipboard"

Vue.use(Clipboard)
export default {
  data() {
    return {
      copy_clipboard: false,
      has_request: false,
      all_channel: true,
      channel_dropdown: false,
      isIndeterminate: false,
    }
  },
  computed: {
    ...mapState({
      business: (state) => state.business || {},
      oho_member: (state) => state.oho_member,
      channels: (state) => state.channels,
    }),
    ...mapGetters(["nav_menu"]),
    current_menu() {
      const menu = this.nav_menu.find((m) => m.path_id === this.path_name)
      return menu || {}
    },
    member() {
      return _.get(this.oho_member, "member") || {}
    },
    path_name() {
      return this.$route.path.split("/")[3]
    },
    current_sub_menu() {
      let current_menu = null
      let current_child_sub_menu = null
      const sub_menu_list = _.get(this.current_menu, "sub_menu_list") || []
      for (let index = 0; index < sub_menu_list.length; index++) {
        const sub_menu = sub_menu_list[index]
        current_menu = sub_menu.childs.find((child_menu) => {
          const child_params = new URLSearchParams(
            child_menu.path.split("?")[1]
          )
          const route_params = new URLSearchParams(
            this.$route.fullPath.split("?")[1]
          )
          const assignee = route_params.get("assignee")
          if (assignee) {
            if (child_menu.sub_menu) {
              current_child_sub_menu = _.find(child_menu.sub_menu, {
                id: assignee,
              })
              return current_child_sub_menu
            } else {
              return false
            }
          } else {
            return route_params.get("status") === child_params.get("status")
          }
        })
        if (current_menu) break
      }
      return current_child_sub_menu
        ? current_child_sub_menu
        : current_menu || {}
    },
  },
  methods: {
    errorHandler() {
      return this.business.image_url ? false : true
    },
    clickCopyClipboard() {
      this.copy_clipboard = true
      setTimeout(() => {
        this.copy_clipboard = false
      }, 3000)
    },
  },
}
