
import Vue from "vue"
import { mapState, mapMutations } from "vuex"
import _ from "lodash"
import vClickOutside from "v-click-outside"
Vue.use(vClickOutside)

export default {
  props: {
    // platform: {
    //   type: String,
    //   default: undefined,
    // },
    checked: {
      type: Array,
      default: () => [],
    },
    apply_filter: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      current_platform: undefined,
      all_channel: true,
      channel_dropdown: false,
      isIndeterminate: false,
      checked_channels: [],
    }
  },
  computed: {
    ...mapState({
      business: (state) => state.business,
      channels: (state) => state.channels,
    }),
    filter_channels() {
      return this.checked != 0
        ? this.checked
        : this.$store.state.checked_channels
    },
    // checked_channels: {
    //   set(val) {
    //     this.setCheckedChannels(val)
    //   },
    //   get() {
    //     return this.checked || this.$store.state.checked_channels
    //   },
    // },
  },
  watch: {
    checked_channels() {
      this.onFilterChange()
    },
    // checked: {
    //   immediate: true,
    //   handler(val) {
    //     this.checked_channels = val == null ? this.filter_channels : val
    //   },
    // },
    checked(val) {
      this.checked_channels = val
    },
    filter_channels: {
      immediate: true,
      handler(val) {
        this.checked_channels = val
      },
    },
  },
  created() {
    // console.log(this.checked)
    // this.checked_channels = this.filter_channels
  },
  methods: {
    ...mapMutations(["setCheckedChannels"]),
    selectChange() {
      this.$emit("change", this.current_platform)
    },
    handleChannelSelectOnly(channel) {
      this.checked_channels = [channel]
      this.all_channel = false
      this.isIndeterminate = true
    },
    configureChecked(channel = {}) {
      let disable = false
      const { length } = this.checked_channels
      const findIndex = this.checked_channels.findIndex((d) => d == channel._id)
      if (length == 1 && findIndex > -1) disable = true

      return disable
    },
    handleCheckAllChange(val) {
      if (val) {
        this.checked_channels = this.channels.map((d) => d._id)
        this.isIndeterminate = false
      } else {
        const frist = _.get(_.head(this.channels), "_id")
        this.checked_channels = [frist]
        this.isIndeterminate = true
      }
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length
      this.all_channel = checkedCount === this.channels.length
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.channels.length
    },
    onFilterChange() {
      const value = this.checked_channels
      if (this.apply_filter) {
        this.setCheckedChannels(value)

        if (this.business?._id) {
          this.$sessionStorageHelper.saveSelectedChannels(
            this.business._id,
            value
          )
        }
      }

      this.$emit("update:checked", value)
      this.$emit("change", value)
    },
  },
}
